var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-pagination", {
        attrs: {
          "show-size-changer": "",
          "default-current": _vm.defaultCurrent,
          total: _vm.total,
          "page-size": _vm.pageSizeSet,
          id: _vm.idPagination,
          simple: this.$store.state.innerWidth < 768 ? true : false,
          current: _vm.defaultCurrent ? _vm.defaultCurrent : _vm.currentPage,
          pageSizeOptions: _vm.pageSizeOptions
        },
        on: { showSizeChange: _vm.onShowSizeChange, change: _vm.change }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }